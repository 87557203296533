.ROW-1{
    grid-row:1;
}

.ROW-2 {
    grid-row: 2;
}

.ROW-3 {
    grid-row: 3;
}

.ROW-4 {
    grid-row: 4;
}

.COL-1 {
    grid-column: 1;
}

.COL-2 {
    grid-column: 2;
}

.COL-3 {
    grid-column: 3;
}

.COL-4 {
    grid-column: 4;
}

.ROW-START-1 {
    grid-row-start: 1;
}

.ROW-START-2 {
    grid-row-start: 2;
}

.ROW-START-3 {
    grid-row-start: 3;
}

.ROW-START-4 {
    grid-row-start: 4;
}

.COL-START-1 {
    grid-column-start: 1;
}

.COL-START-2 {
    grid-column-start: 2;
}

.COL-START-3 {
    grid-column-start: 3;
}

.COL-START-4 {
    grid-column-start: 4;
}
.COL-START-5 {
    grid-column-start: 4;
}

.ROW-END-1 {
    grid-row-end: 1;
}

.ROW-END-2 {
    grid-row-end: 2;
}

.ROW-END-3 {
    grid-row-end: 3;
}

.ROW-END-4 {
    grid-row-end: 4;
}

.COL-END-1 {
    grid-column-end: 1;
}

.COL-END-2 {
    grid-column-end: 2;
}

.COL-END-3 {
    grid-column-end: 3;
}

.COL-END-4 {
    grid-column-end: 4;
}
.COL-END-5 {
    grid-column-end: 5;
}

.CIntorTextColored {
    font-size: 21px; 
    color:#FFCC99; 
}

.CIMAGE {
    height: calc(100% + 2em);
    width:auto;
    z-index: 500;
}
.VideoHeader {
    color: #ffcc99;
    font-size: 20px;
}

.HMenuContainer {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    display: grid;
    grid-template-columns: 10% 30% 30% 30%;
    background: #333a56;
    height: 40px;
    z-index: 510;
}
.HMenuLabel {
    font-size: 26px;
    color: white;
    cursor: pointer;
    align-self: center;
}

.HMenuLabel[isselected="true"] {
    color:yellow !important;
}

    .G0 {
        grid-row: 1;
        grid-column: 1;
    }

.G1 {
    grid-row: 1;
    grid-column: 2;
}

.G2 {
    grid-row: 1;
    grid-column: 3;
}

.G3 {
    grid-row: 1;
    grid-column: 4;
}

.G4 {
    grid-row: 1;
    grid-column: 5;
}
.HCloseIcon {
    color: red;
    display: block;
    cursor: pointer;
    float: left;
    margin: 0.1em 0.5em 0em 0.5em;
    font-size: 20px;
}

.HItemContainer {
    line-height: 1.5em;
    text-align: right;
    font-size: 1em;
    padding-right: 0.5em;
}

    .HItemContainer:nth-child(odd) {
        background: #505050;
        color: white !important;
    }

    .HItemContainer:nth-child(even) {
        background: #707070;
    
    }

a   {
    color: white !important;
}

.HTextIntroContainer {
    color: white;
    text-align: justify;
    padding: 0.5em;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 2em);
    width: calc(100% - 1em);
    margin-top: 1.4em ;
}

* {
    scrollbar-width: thin;
}

*::-webkit-scrollbar {
    width: 12px;
}

.HSelectedDetailsConstainer {
    display: block;
    padding: 0em 0em 0em 0em;
    background: #505050;
    text-align: center;
    box-sizing: content-box;
    opacity: 1 !important;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 520;
}

.HList[selectedindex="0"] {
   background: transparent !important; 
}

.HList[selectedindex="9"] {
    grid-row: 1;
    grid-column: 1;
}

.HList {
    display: grid;
    background: #505050;
    opacity: 1;
    grid-template-rows: 2em;
    font-size: 16px;
    max-height: calc(100% - 4em);
}

.HList[selectedindex="10"] {
    grid-row: 1;
    grid-column: 3;
}

.HItemLabel {
    display: block;
    cursor: pointer;
    margin: auto 1em auto auto;
}

.CItemDesc {
    margin-left:1em;
}

.FButtonContainer {
	display: block;
	height: 80%;
    margin-left: 0.5em;
}

.FactionButton {
    width: 10em;
    max-width: 10em;
    min-width:10em;
    margin-top: 2em;
    margin-right: 0.5em;
    display: block;
    border-radius: 2em;
    line-height: 1.2em;
    height: 1.4em;
    cursor: pointer;
    color: black !important;
    background-color: #ffc83f;
    text-decoration: none;
}
.FactionButton:first-child {
    margin-top: 4em;
}

.FactionButton:hover {
    color: white;
}

@media only screen and (min-width: 1500px) {
    .FIntroTextP {
        font-size: 20px;
    }
    .FactionButton {
        font-size: 18px;
        width: 10em !important;
    }
}

@media only screen and (max-width: 1500px) {
    .FIntroTextP {
        font-size: 18px;
    }
    .FactionButton {
        font-size: 18px;
        width: 10em !important;
    }
}

@media only screen and (max-width: 1300px) {
    .FIntroTextP {
        font-size: 17px;
    }
    .FactionButton {
        font-size: 18px;
        width: 9em !important;
    }
   
}

@media only screen and (max-width: 1000px) {
    .FIntroTextP {
        font-size: 14px;
    }
    .FactionButton {
        font-size: 14px;
        width: 8em !important;
    }
}
@media only screen and (max-width: 800px) {
    .FIntroTextP {
        font-size: 12px;
    }
    .FactionButton {
        font-size: 12px;
        width: 8em !important;
    }
}

@media only screen and (max-width: 700px) {
    .FIntroTextP {
        font-size: 14px;
    }
    .FactionButton {
        font-size: 14px;
    }
}

@media only screen and (max-width: 400px) {
    .FIntroTextP {
        font-size: 12px;
    }
    .FactionButton {
        font-size: 12px;
    }
}

@media only screen and (max-width: 800px) {
    .HMenuLabel {
        font-size: 16px;
    }

    .HMenuContainer {
        grid-template-columns: 20% 20% 20% 20% 20%;
    }

    .HSelectedDetailsConstainer {
        top: 0.5em !important;
        padding-left: 0.5em;
        padding-right: 0.5em;
    }

    .HItemContainer {
        font-size: 14px;
    }

}

@media only screen and (max-width: 460px) and (min-height: 300px) {
    .FactionButton:first-child {
        width: 3em;
    } 
    .FactionButton {
        width: 3em;
    } 
    .HMenuContainer {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        display: grid;
        grid-template-columns: 10% 30% 30% 30%;
        background: #333a56;
        height: 30px;
        z-index: 510;
    }
    .HMenuLabel {
        font-size: 12px;
        color: white;
        cursor: pointer;
        align-self: center;
    }
    .CIMAGE {
        width: 100%;
        height: auto;
        grid-column-start: 3 !important;
        grid-column-end: 4 !important;
        grid-row: 3 !important;
    }
    .HMainPage {
        grid-template-columns: 0.1em calc(100% - 7.1em) 7em;
    }
}