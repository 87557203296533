.App {
    text-align: center;
    width: 100%;
    height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
    background-position: left;
    background-repeat: no-repeat;
    background-size: auto 100%;
	background-color: #505050;
    opacity:1;
}

#root {
    width: 100%;
    height: 100%;
}
.HMainPage {
    width: 100%;
    height: 100%;
    display: grid;
   
    grid-template-rows: 2em calc(50% - 2em) calc(50% - 2em) 2em;
}

.CClosePane {
    display: block;
    color: red;
    cursor: pointer;
    direction: ltr;
}

.FIntroTextP {
    line-height: 1.7em;
    margin: 0px !important;
    font-size: 20px;
}
.HImageContainer {
    display: flex;
}

.ImageItem {
    width: 7em;
    height: auto;
} 

.HFooter {
    background-color: blue;
    color:white;
    height: 2em;
    align-self: end;
    width:100%;
}

.HFooterRow {
    display:table-row;
}

.HFooterCell {
    display: table-cell;
}


.Hcontact {
    line-height: 2em;
    margin: 0px !important;
    color: white;
}
.HFooterLink {
    color:white;
    align-self: center;
}


